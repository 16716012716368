const accordionHeader = document.querySelectorAll('.footer-menu-title');

let i;
for (i = 0; i < accordionHeader.length; i++) {
  accordionHeader[i].addEventListener('click', (event) => {
    event.preventDefault();
    event.target.classList.toggle('active');
    const panel = event.target.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = `${panel.scrollHeight}px`;
    }
  });
}
