// footer accordion
(function footerAccordion() {
	const footerAccordionHeader =
		document.querySelectorAll('.footer-menu-title');
	let i;
	for (i = 0; i < footerAccordionHeader.length; i++) {
		footerAccordionHeader[i].addEventListener('click', (event) => {
			event.preventDefault();
			event.target.classList.toggle('active');
			const panel = event.target.nextElementSibling;
			if (panel.style.maxHeight) {
				panel.style.maxHeight = null;
			} else {
				panel.style.maxHeight = `${panel.scrollHeight}px`;
			}
		});
	}
})();

// Add event listener to track clicks on main navigation
document.querySelectorAll('.menu-main-menu-container a').forEach((anchor) => {
	anchor.addEventListener('click', () => {
		window.dataLayer.push({
			event: 'Main Navigation Click',
			item: anchor.textContent.trim(),
		});
	});
});

// open cookie banner
const cookieButtonDisplay = document.querySelector('#display-cookie-banner');
cookieButtonDisplay.addEventListener('click', () => {
	const currentUrl = window.location.href;
	const newUrl = currentUrl.includes('?')
		? `${currentUrl}&cmpscreen`
		: `${currentUrl}?cmpscreen`;
	window.location.href = newUrl;
});
