import { initBanner } from './functions';

initBanner();

function setHeaderState(header) {
  const scrollPosition = window.scrollY || document.documentElement.scrollTop;
  if (scrollPosition > 0) {
    header.classList.add('fixed');
  } else {
    header.classList.remove('fixed');
  }
}

// remove focus from dropdown menu to prevent multiple active dropdowns
const menuItems = document.querySelectorAll('.site-header__main-nav li a');
menuItems.forEach((item) => {
  item.addEventListener('click', function () {
    if (document.activeElement) {
      document.activeElement.blur();
    }
  });
});

document.addEventListener('DOMContentLoaded', function () {
  const siteHeader = document.querySelector('.site-header');
  const toggleBtn = document.getElementById('menu-toggle');
  toggleBtn.addEventListener('click', function () {
    if (toggleBtn.getAttribute('data-state') === 'open') {
      // close menu
      siteHeader.classList.remove('active');
      toggleBtn.setAttribute('data-state', 'closed');
    } else {
      // open menu
      siteHeader.classList.add('active');
      toggleBtn.setAttribute('data-state', 'open');
    }
  });
  window.addEventListener('scroll', function () {
    setHeaderState(siteHeader);
  });

  setHeaderState(siteHeader);

  const dropdownToggles = document.querySelectorAll('.dropdown-toggle');
  dropdownToggles.forEach((toggle) => {
    toggle.addEventListener('click', function () {
      const dropdownState = !this.checked;
      dropdownToggles.forEach((t) => (t.checked = false));
      this.checked = !dropdownState;
    });
  });
});
